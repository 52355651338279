
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  private loading = false
  private searchInfo = {
    serviceName: '',
    serviceAppKey: ''
  }

  private tableData = []
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getStateName (state: number) {
    return state === 0 ? '禁用' : state === 1 ? '启用' : '--'
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.privatization.selectPrivatizationByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.tableData = res.list || []
      this.total = res.total || 0
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  privatizationAdd () {
    this.$router.push({
      name: 'privatizationAdd'
    })
  }

  // 详情
  onDetails (id: string) {
    this.$router.push({
      name: 'privatizationDetail',
      params: { id }
    })
  }

  // 编辑
  onUpdate (id: string) {
    this.$router.push({
      name: 'privatizationUpdate',
      params: { id }
    })
  }

  // 激活
  onActivation (data: any) {
    this.$confirm(`确认${this.getStateName(data.isDel)}吗, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.privatization.updatePrivatizationState, {
        serviceId: data.serviceId
      }).then(() => {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.getData()
      })
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.privatization.deletePrivatization, {
        serviceId: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.onSearch()
      })
    })
  }
}
